import { Routes, Route } from 'react-router-dom'
import { useAuth } from './contexts/AuthContext'
import Login from './components/auth/Login'
import SignUp from './components/auth/SignUp'
import Profile from './components/pages/Profile'
import Layout from './components/layout/Layout'
import Questionnaire from './components/questionnaire/Questionnaire'
import Dashboard from './components/pages/Dashboard'

const App = () => {
  const { loading } = useAuth()

  if (loading) return <div>Loading...</div>

  return (
    <Layout>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/questionnaire" element={<Questionnaire />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/" element={<Dashboard />} />
      </Routes>
    </Layout>
  )
}

export default App
